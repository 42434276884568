<template>
  <v-card>
    <v-toolbar color="teal" dark v-if="enableHeader">
      <v-toolbar-title>Selecionar vendedores</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        class="mb-n10 mt-4"
        hide-details
        append-icon="mdi-magnify"
        name="Nome"
        label="Pesquisar vendedor"
        placeholder="Informe o nome do vendedor..."
        id="id"
        outlined
        v-model="search"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-if="employees"
      v-model="selecionados"
      :headers="headers"
      :items="employees"
      item-key="id"
      show-select
      :search="search"
    >
      <template v-slot:header.data-table-select></template>
      <!-- eslint-disable-line-->
    </v-data-table>
    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <div class="my-2">
        <v-btn color="primary" dark x-large depressed large @click="send()">
          Salvar
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "stepEmployees",
  props: ["idTeam", "enableHeader"],
  data: () => {
    return {
      search: null,
      selecionados: [],
      employees: [],
      headers: [{ text: "Funcionario", value: "name" }],
      bulk: {
        team: null,
        employees: []
      }
    };
  },

  created() {
    this.getAllEmployees();
  },

  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async getAllEmployees() {
      let temporary_employees = [];
      const result = await this.$http.get("/employe");
      this.employees = result.data;
      this.employees.forEach(function(data) {
        if (data.team === null) {
          temporary_employees.push(data);
        }
      });
      this.employees = temporary_employees;
    },
    async send() {
      let temporary_employe = [];
      try {
        this.selecionados.forEach(function(data) {
          temporary_employe.push(data.id);
        });
        this.bulk.employees = temporary_employe;
        this.bulk.team = this.idTeam;
        await this.$http.put("/employe/bulk", this.bulk);
        this.$toast.success("Funcionarios adicionados com sucesso");
        setTimeout(() => {
          this.$emit("close-dialog");
        }, 1000);
      } catch (error) {
        this.$toast.error("Erro ao inserir funcionarios");
      }
    }
  }
};
</script>

<style></style>
