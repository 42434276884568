<template>
  <v-card flat>
    <v-toolbar color="teal" dark>
      <v-toolbar-title>Remover Time</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon v-on:click="$emit('close-dialog')"
        ><v-icon arge>mdi-close-circle</v-icon></v-btn
      >
    </v-toolbar>
    <v-card-title> Você deseja remover este time?</v-card-title>
    <v-card-text>
      <v-alert dense outlined type="error" class="mt-2">
        Atenção! Ao confirmar a alteração,
        <strong> o time será removido</strong>!
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="closeDialog()">
        <v-icon arge>mdi-close</v-icon> Cancelar</v-btn
      >
      <v-btn color="success" text @click="deleteTeam()">
        <v-icon arge>mdi-check</v-icon> Confirmar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RomoveTeam",
  props: ["status"],

  data() {
    return {
      team: Object.assign({}, this.status)
    };
  },
  created() {},
  methods: {
    async closeDialog() {
      this.$emit("close-dialog");
    },
    async deleteTeam() {
      try {
        const response = await this.$http.delete(`/team/${this.team.id}`);
        if (response) this.$toast.success("Time excluido com sucesso!");
        this.$emit("update");
        this.$emit("close-dialog");
      } catch (error) {
        this.$toast.error("Tente novamente mais tarde!");
        this.$emit("error");
        this.$emit("close-dialog");
      }
    }
  }
};
</script>

<style></style>
