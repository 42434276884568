<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="teams"
          :headers="headers"
          :items="teams"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Times</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{}">
                  <v-btn class="mt-2 mb-2" icon x-large to="/times/adicionar">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Adicionar novo time
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="addEmployees(item)" class="mr-2" color="primary"
              >mdi-account-multiple-plus</v-icon
            >
            <v-icon @click="showRefTeam(item)" class="mr-2" color="primary"
              >mdi-eye</v-icon
            >
            <v-icon @click="edit(item)" class="mr-2 colorLink"
              >mdi-square-edit-outline</v-icon
            >
            <v-icon @click="remove(item)" class="mr-2" color="error"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editTeam
        :status="team"
        v-on:update="loadTeams()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeTeam
        :status="team"
        v-on:update="loadTeams()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>

    <v-divider></v-divider>
    <v-dialog
      class="justify-center"
      v-model="dialog"
      v-if="dialog"
      max-width="900px"
      transition="dialog-transition"
    >
      <stepTeam
        v-on:update-team="getTeams()"
        v-on:close-dialog="dialog = false"
      />
    </v-dialog>
    <v-dialog
      class="justify-center"
      v-if="dialogStepRef"
      v-model="dialogStepRef"
      max-width="900px"
      transition="dialog-transition"
    >
      <newRefTeam
        :idTeam="idteam"
        v-on:close-dialog="closeSeller()"
        v-if="dialogStepRef"
        :enableHeader="true"
      />
    </v-dialog>

    <v-dialog
      class="justify-center"
      v-if="dialogStepTwo"
      v-model="dialogStepTwo"
      max-width="900px"
      transition="dialog-transition"
    >
      <stepEmployees
        :idTeam="idteam"
        v-on:close-dialog="dialogStepTwo = !dialogStepTwo"
        v-if="dialogStepTwo"
        :enableHeader="true"
      />
    </v-dialog>

    <v-dialog
      class="justify-center"
      v-if="dialogShowRef"
      v-model="dialogShowRef"
      max-width="900px"
      transition="dialog-transition"
    >
      <showRef
        :idTeam="idteam"
        v-on:close-dialog="closeListRef()"
        v-if="dialogShowRef"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import editTeam from "./edit";
import removeTeam from "./remove";
import stepEmployees from "./stepEmployees";
export default {
  components: {
    editTeam,
    removeTeam,
    stepEmployees
  },
  data() {
    return {
      teams: [],
      team: {},
      editDialog: false,
      removeDialog: false,
      dialogStepTwo: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Time", value: "name" },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  created() {
    this.loadTeams();
  },
  methods: {
    async loadTeams() {
      const response = await this.$http.get("/team");
      this.teams = response.data;
    },
    async edit(team) {
      this.editDialog = true;
      this.team = team;
    },
    async remove(team) {
      this.removeDialog = true;
      this.team = team;
    },
    async closeEmployees() {
      this.dialogStepTwo = false;
    },
    async closeListRef() {
      this.dialogShowRef = false;
    },
    async addEmployees(item) {
      this.idteam = item.id;
      this.dialogStepTwo = true;
    },
    async addRefTeam(item) {
      this.idteam = item.id;
      this.dialogStepRef = true;
    },
    async showRefTeam(item) {
      this.idteam = item.id;
      this.dialogShowRef = true;
    }
  }
};
</script>

<style></style>
